import * as React from "react";
import {
  List,
  DatagridConfigurable,
  TextField,
  NumberInput,
  SelectColumnsButton,
  TopToolbar,
  FilterButton,
  DateField,
  TextInput
} from "react-admin";

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
);

const filters = [
  <NumberInput key={0} source="id" label="id" min={1} />,
  <TextInput key={1} source="name" label="name" />,
  <TextInput key={2} source="email" label="email" />,
  <TextInput key={3} source="phone" label="phone" />,
];

export const ContactsList = () => {
  return (
    <List
      filters={filters}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions />}
    >
      <DatagridConfigurable rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <TextField source="name" />
        <TextField source="phone" />
        <TextField source="email" />
        <DateField source="createdAt" showTime locales="lv-LV" />
      </DatagridConfigurable>
    </List>
  );
};
