import * as React from 'react';
import { Create, SimpleForm, TextInput, required, TranslatableInputs } from "react-admin";
import { locales } from "../enum/locales";

export const ContentCreateForm = () => (
  <SimpleForm>
    <TextInput source="code" validate={[required()]} />
    <TranslatableInputs locales={locales} defaultLocale="en" fullWidth={true} >
      <TextInput source="title" validate={[required()]} />
      <TextInput source="description" multiline={true} fullWidth />
    </TranslatableInputs>
  </SimpleForm>
);
export const ContentCreate = () => (
  <Create>
    <ContentCreateForm />
  </Create>
);

