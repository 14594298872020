import * as React from 'react';
import { Create, SimpleForm, TextInput, required, TranslatableInputs, NumberInput } from "react-admin";
import { locales } from "../enum/locales";

export const FaqCreateForm = () => (
  <SimpleForm>
    <TranslatableInputs locales={locales} defaultLocale="en" fullWidth={true} >
      <TextInput source="question" validate={[required()]} />
      <TextInput source="answer" validate={[required()]} multiline={true} fullWidth />
    </TranslatableInputs>
    <NumberInput source="position" validate={[required()]} defaultValue={0} />
  </SimpleForm>
);
export const FaqCreate = () => (
  <Create>
    <FaqCreateForm />
  </Create>
);

