import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  required,
  TranslatableInputs,
  ImageInput,
  ImageField,
  DateTimeInput
} from "react-admin";
import { RichTextInput, RichTextInputToolbar } from "ra-input-rich-text";
import { locales } from "../enum/locales";

export const LifestyleCreateForm = () => (
    <SimpleForm>
      <ImageInput source="file" label="Main image">
        <ImageField source="src" title="title" />
      </ImageInput>
      <ImageInput source="files" label="Gallery" multiple={true}>
        <ImageField source="src" title="title" />
      </ImageInput>
        <TranslatableInputs locales={locales} defaultLocale="en" fullWidth={true}>
            <TextInput source="title" validate={[required()]} />
            <RichTextInput source="summary" fullWidth />
            <RichTextInput source="body" variant={"filled"} fullWidth={true} toolbar={<RichTextInputToolbar size="large" />} />
        </TranslatableInputs>
      <DateTimeInput source="publishedAt" />
    </SimpleForm>
);
export const LifestyleCreate = () => (
    <Create>
        <LifestyleCreateForm />
    </Create>
);

