import * as React from "react";
import {
  Create,
  ImageInput,
  ImageField,
  SimpleForm, required, NumberInput
} from "react-admin";

export const HomeBannersCreateForm = () => (
  <SimpleForm>
    <ImageInput source="file" label="Related picture">
      <ImageField source="src" title="title" />
    </ImageInput>
    <NumberInput source="position" validate={[required()]} defaultValue={0} />
  </SimpleForm>
);
export const HomeBannersCreate = () => (
  <Create>
    <HomeBannersCreateForm />
  </Create>
);

