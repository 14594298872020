import * as React from 'react';
import {
  List,
  DatagridConfigurable,
  TextField,
  EditButton,
  NumberInput,
  SelectColumnsButton,
  TopToolbar,
  FilterButton,
  CreateButton,
  DateInput, DateField,
  useLocaleState, NumberField, FunctionField
} from "react-admin";
import { truncate } from "../helper/truncate";

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <CreateButton />
  </TopToolbar>
);

const filters = [
    <NumberInput key={0} source="id" label="id" min={1} />,
    <NumberInput key={0} source="position" label="position" />,
    <DateInput key={6} source="createdAt#$gte" label="Start Created At" />,
    <DateInput key={7} source="createdAt#$lte" label="End Created At" />,
];

export const OurContactList = () => {
  const [locale] = useLocaleState();

  return (
    <List
      filters={filters}
      sort={{ field: 'position', order: 'ASC' }}
      actions={<ListActions />}
    >
      <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />

        <TextField source={`title.${locale}`} />
        <FunctionField
          label={'Description'}
          render={(record: any) => truncate(record.description[locale], 100)}
        />
        <NumberField name={'position'} source={'position'} />

        <DateField source="createdAt" showTime locales="lv-LV" />
        <EditButton />
      </DatagridConfigurable>
    </List>
  )
};
