import { CondOperator, QueryFilter, QuerySort, RequestQueryBuilder } from "@nestjsx/crud-request";
import {
  fetchUtils,
  GetListParams,
  GetOneParams,
  GetManyParams,
  GetManyReferenceParams,
  UpdateParams,
  UpdateManyParams,
  CreateParams,
  DeleteManyParams,
  DeleteParams
} from "react-admin";
import { stringify } from "query-string";

const apiUrl = `${process.env.REACT_APP_BASE_API_URL}`;
const httpClient = fetchUtils.fetchJson;

const composeFilter = (paramsFilter: any): QueryFilter[] => {
  const flatFilter = fetchUtils.flattenObject(paramsFilter);
  return Object.keys(flatFilter).map((key) => {
    const splitKey = key.split("#");
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/gi;

    let field = splitKey[0];
    let ops = splitKey[1];
    if (!ops) {
      if (typeof flatFilter[key] === "boolean" || typeof flatFilter[key] === "number" || (typeof flatFilter[key] === "string" && (flatFilter[key].match(/^\d+$/)) || flatFilter[key].match(uuidRegex))) {
        ops = CondOperator.EQUALS;
      } else {
        ops = CondOperator.CONTAINS;
      }
    }

    if (field.startsWith("_") && field.includes(".")) {
      field = field.split(/\.(.+)/)[1];
    }

    return { field, operator: ops, value: flatFilter[key] } as QueryFilter;
  });
};

const composeQueryParams = (queryParams: any = {}): string => {
  return stringify(fetchUtils.flattenObject(queryParams), { skipNull: true });
};

const mergeEncodedQueries = (...encodedQueries: any) => encodedQueries.map((query: any) => query).join("&");

const convertFileToBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(file.rawFile);
  });

const formDataPrepare = (params: any) => {
  const formData = new FormData();
  const data: any = {};
  for ( const param in params.data ) {
    // 1 file
    if (param === 'file' && params.data[param]) {
      if (params.data[param].rawFile) {
        formData.append('file', params.data[param].rawFile ?? params.data[param].src);
      } else {
        data.existingFile = params.data[param].src;
      }
      continue;
    }

    // when using multiple files
    if (param === 'files' && params.data[param]?.length) {
      params.data[param].forEach((file: any) => {
        if (file.rawFile) {
          formData.append('files', file.rawFile ?? file.src);
        } else {
          if (!data.existingFiles) {
            data.existingFiles = [];
          }
          data.existingFiles.push(file.src);
        }
      });
      continue;
    }

    if (param !== 'file' && param !== 'files') {
      data[param] = params.data[param];
    }
  }
  formData.append('data', JSON.stringify(data))
  return formData;
}

export const dataProvider = {
  getList: (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams, $OR: orFilter, ...filter } = params.filter || {};

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: composeFilter(filter),
      or: composeFilter(orFilter || {})
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort as QuerySort)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ json }) => ({
      data: json.data,
      total: json.total
    }));
  },

  getOne: (resource: string, params: GetOneParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json
    })),

  getMany: (resource: string, params: GetManyParams) => {
    const perPage = 100;
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: {
        field: "id",
        operator: CondOperator.IN,
        value: params.ids
      } as QueryFilter
    })
      .setLimit(perPage)
      .query();
    const url = `${apiUrl}/${resource}?${encodedQueryFilter}`;
    return httpClient(url).then(({ json }) => ({ data: json.data, total: json.total }));
  },

  getManyReference: (resource: string, params: GetManyReferenceParams) => {
    const { page, perPage } = params.pagination;
    const { q: queryParams } = params.filter || {};

    const encodedQueryParams = composeQueryParams(queryParams);
    const encodedQueryFilter = RequestQueryBuilder.create({
      filter: {
        field: params.target,
        operator: CondOperator.EQUALS,
        value: params.id
      } as QueryFilter
    })
      .setLimit(perPage)
      .setPage(page)
      .sortBy(params.sort as QuerySort)
      .setOffset((page - 1) * perPage)
      .query();

    const query = mergeEncodedQueries(encodedQueryParams, encodedQueryFilter);

    const url = `${apiUrl}/${resource}?${query}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json.data,
      total: json.total
    }));
  },

  create: (resource: string, params: CreateParams) => {
    const formData = formDataPrepare(params);

    return httpClient(`${apiUrl}/${resource}`, {
      method: "POST",
      // body: JSON.stringify(params.data),
      body: formData,
    }).then(({ json }) => ({
      data: { ...json }
    }));
  },

  update: (resource: string, params: UpdateParams) => {
    const formData = formDataPrepare(params);

    return httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "PATCH",
      body: formData
    }).then(({ json }) => ({ data: json }));
  },

  updateMany: (resource: string, params: UpdateManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "PUT",
      body: JSON.stringify(params.data)
    }).then(({ json }) => ({ data: json }));
  },

  delete: (resource: string, params: DeleteParams) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: "DELETE"
    }).then(({ json }) => ({ data: json })),

  deleteMany: (resource: string, params: DeleteManyParams) => {
    const query = {
      filter: JSON.stringify({ id: params.ids })
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: "DELETE",
      body: JSON.stringify(params)
    }).then(({ json }) => ({ data: json }));
  }
};

