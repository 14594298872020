import * as React from 'react';
import {
  Create,
  FormDataConsumer,
  TextInput,
  ImageInput,
  required,
  TranslatableInputs,
  ImageField,
  NumberInput,
  TabbedForm,
  SimpleFormIterator,
  ArrayInput, BooleanInput
} from "react-admin";
import { locales } from "../enum/locales";

export const CarCreateMainForm = () => (
  <>
    <ImageInput source="files" label="Related pictures" multiple={true}>
      <ImageField source="src" title="title" />
    </ImageInput>
    <TranslatableInputs locales={locales} defaultLocale="en" fullWidth={true} >
      <TextInput source="title" validate={[required()]} fullWidth={true} />
    </TranslatableInputs>
    <BooleanInput source="isForSell" validate={[required()]} value={true} />
    <BooleanInput source="isForRent" validate={[required()]} value={true} />
    <BooleanInput source="isNewSchool" validate={[required()]} value={true} />
    <NumberInput source="position" validate={[required()]} defaultValue={0} />
  </>
);

const CarCreatePricesForm = () => (
  <>
    <NumberInput source="carSellPrice"
                 validate={[required()]}
                 step={0.01}
                 defaultValue={0}
                 format={v => String(v / 100)}
                 parse={v => parseFloat(v) * 100} />
    <NumberInput source="priceFirst"
                 validate={[required()]}
                 step={0.01}
                 defaultValue={0}
                 format={v => String(v / 100)}
                 parse={v => parseFloat(v) * 100} />
    <NumberInput source="priceSecond"
                 validate={[required()]}
                 step={0.01}
                 defaultValue={0}
                 format={v => String(v / 100)}
                 parse={v => parseFloat(v) * 100} />
    <NumberInput source="priceThird"
                 validate={[required()]}
                 step={0.01}
                 defaultValue={0}
                 format={v => String(v / 100)}
                 parse={v => parseFloat(v) * 100} />
    <NumberInput source="priceFourth"
                 validate={[required()]}
                 step={0.01}
                 defaultValue={0}
                 format={v => String(v / 100)}
                 parse={v => parseFloat(v) * 100} />
    <NumberInput source="deposit"
                 validate={[required()]}
                 step={0.01}
                 defaultValue={0}
                 format={v => String(v / 100)}
                 parse={v => parseFloat(v) * 100} />
  </>
);

const CarCreateAboutForm = () => (
  <>
    <TranslatableInputs locales={locales} defaultLocale="en" fullWidth={true} >
      <TextInput source="about" validate={[required()]} multiline={true} fullWidth={true} />
      <TextInput source="color" multiline={false} fullWidth={true} />
    </TranslatableInputs>
    <NumberInput source="year" />
    <NumberInput source="seat" />
    <TextInput source="acceleration" multiline={false} fullWidth={true} />
    <TextInput source="engine" multiline={false} fullWidth={true} />
    <TextInput source="topSpeed" multiline={false} fullWidth={true} />
    <ArrayInput source="additional">
      <SimpleFormIterator fullWidth inline>

          <FormDataConsumer>
            {({
                formData, // The whole form data
                scopedFormData, // The data for this item of the ArrayInput
                getSource, // A function to get the valid source inside an ArrayInput
                ...rest
              }) =>
              
                <TranslatableInputs locales={locales} defaultLocale="en" fullWidth={true} >
                  <TextInput source={getSource('title')} fullWidth={true} />
                </TranslatableInputs>

            }
          </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
  </>
);

export const CarCreateForm = () => (
  <TabbedForm>
    <TabbedForm.Tab label='main'>
      <CarCreateMainForm />
    </TabbedForm.Tab>
    <TabbedForm.Tab label='prices'>
      <CarCreatePricesForm />
    </TabbedForm.Tab>
    <TabbedForm.Tab label='about'>
      <CarCreateAboutForm />
    </TabbedForm.Tab>
  </TabbedForm>
);
export const CarCreate = () => (
  <Create>
    <CarCreateForm />
  </Create>
);

