import * as React from 'react';
import {
  List,
  DatagridConfigurable,
  TextField,
  EditButton,
  NumberInput,
  SelectColumnsButton,
  TopToolbar,
  FilterButton,
  CreateButton,
  DateInput, DateField,
  useLocaleState, NumberField, BooleanField, BooleanInput
} from "react-admin";

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
    <CreateButton />
  </TopToolbar>
);

const filters = [
    <NumberInput key={0} source="id" label="id" min={1} />,
    <BooleanInput key={1} source="isForRent" value={true} />,
    <BooleanInput key={2} source="isForSell" value={true} />,
    <BooleanInput key={3} source="isNewSchool" value={true} />,
    <DateInput key={4} source="createdAt#$gte" label="Start Created At" />,
    <DateInput key={5} source="createdAt#$lte" label="End Created At" />,
];

export const CarList = () => {
  const [locale] = useLocaleState();

  return (
    <List
      filters={filters}
      sort={{ field: 'position', order: 'ASC' }}
      actions={<ListActions />}
    >
      <DatagridConfigurable rowClick="edit" bulkActionButtons={false}>
        <TextField source="id" />

        <TextField source={`title.${locale}`} />

        <DateField source="createdAt" showTime locales="lv-LV" />
        <BooleanField source={`isForRent`} />
        <BooleanField source={`isForSell`} />
        <NumberField source={`carSellPrice`} transform={(value: any) => Number(String(value / 100))} />
        <NumberField source={`deposit`} transform={(value: any) => Number(String(value / 100))} />
        <NumberField source={`position`} />
        <EditButton />
      </DatagridConfigurable>
    </List>
  )
};
