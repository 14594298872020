import * as React from 'react';
import { Admin, Resource } from "react-admin";
import { FaqList } from '../faq/FaqList';
import { dataProvider } from "../dataProvider/dataProvider";
import { AdminLayout } from '../layout/AdminLayout';
import { FaqEdit } from "../faq/FaqEdit";
import { FaqCreate } from "../faq/FaqCreate";
import { CarEdit } from "../car/CarEdit";
import { CarCreate } from "../car/CarCreate";
import { CarList } from "../car/CarList";
import { CarRentList } from "../carRent/CarRentList";
import { ContactsList } from "../contacts/ContactsList";
import { HomeBannersList } from "../homeBanners/HomeBannersList";
import { HomeBannersEdit } from "../homeBanners/HomeBannersEdit";
import { HomeBannersCreate } from "../homeBanners/HomeBannersCreate";
import { CarTuningList } from "../carTuning/CarTuningList";
import { CarTuningEdit } from "../carTuning/CarTuningEdit";
import { CarTuningCreate } from "../carTuning/CarTuningCreate";
import { ContentList } from "../content/ContentList";
import { ContentEdit } from "../content/ContentEdit";
import { ContentCreate } from "../content/ContentCreate";
import { OurContactList } from "../ourContacts/OurContactList";
import { OurContactEdit } from "../ourContacts/OurContactEdit";
import { OurContactCreate } from "../ourContacts/OurContactCreate";
import { LifestyleList } from "../lifestyle/LifestyleList";
import { LifestyleEdit } from "../lifestyle/LifestyleEdit";
import { LifestyleCreate } from "../lifestyle/LifestyleCreate";

const App = () => (
  <Admin layout={AdminLayout} dataProvider={dataProvider}>
      <Resource name="homeBanners" list={HomeBannersList} edit={HomeBannersEdit} create={HomeBannersCreate} />
      <Resource name="car" list={CarList} edit={CarEdit} create={CarCreate} />
      <Resource name="carTuning" list={CarTuningList} edit={CarTuningEdit} create={CarTuningCreate} />
      <Resource name="faq" list={FaqList} edit={FaqEdit} create={FaqCreate} />
      <Resource name="content" list={ContentList} edit={ContentEdit} create={ContentCreate} />
      <Resource name="ourContact" list={OurContactList} edit={OurContactEdit} create={OurContactCreate} />
      <Resource name="lifestyle" list={LifestyleList} edit={LifestyleEdit} create={LifestyleCreate} />
      <Resource name="carRent" list={CarRentList} />
      <Resource name="contacts" list={ContactsList} />
  </Admin>
);

export default App;
