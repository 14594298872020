import * as React from 'react';
import {
  Layout,
  AppBar,
  LayoutProps,
  AppBarProps,
  LocalesMenuButton,
  TitlePortal,
} from 'react-admin';
import { Box, Typography, Toolbar } from '@mui/material';
import { AdminMenu } from '../menu/AdminMenu';

const AdminAppBar = (props: AppBarProps) => (
  <AppBar {...props}>
    <Box flex="1">
        <Typography variant="h6" id="react-admin-title"></Typography>
    </Box>
    <Toolbar>
      <TitlePortal />
      <LocalesMenuButton languages={[
        { locale: 'en', name: 'English' },
        { locale: 'ar', name: 'Arabian' },
      ]} />
    </Toolbar>
  </AppBar>
);

export const AdminLayout = (props: LayoutProps) => <Layout {...props} appBar={AdminAppBar} menu={AdminMenu}  />;
