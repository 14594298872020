import * as React from "react";
import {
  List,
  DatagridConfigurable,
  TextField,
  NumberInput,
  SelectColumnsButton,
  TopToolbar,
  FilterButton,
  DateInput, DateField,
  ReferenceField, TextInput
} from "react-admin";

const ListActions = () => (
  <TopToolbar>
    <SelectColumnsButton />
    <FilterButton />
  </TopToolbar>
);

const filters = [
  <NumberInput key={0} source="id" label="id" min={1} />,
  <NumberInput key={1} source="carId" label="carId" min={1} />,
  <TextInput key={2} source="name" label="name" />,
  <TextInput key={3} source="phone" label="phone" />,
  <DateInput key={4} source="pickUpDate#$gte" label="PickUp Start At" />,
  <DateInput key={5} source="pickUpDate#$lte" label="PickUp End At" />,
  <DateInput key={6} source="dropOffDate#$gte" label="DropOff Start At" />,
  <DateInput key={7} source="dropOffDate#$lte" label="DropOff End At" />,
  <DateInput key={8} source="createdAt#$gte" label="Start Created At" />,
  <DateInput key={9} source="createdAt#$lte" label="End Created At" />
];

export const CarRentList = () => {
  return (
    <List
      filters={filters}
      sort={{ field: "id", order: "DESC" }}
      actions={<ListActions />}
    >
      <DatagridConfigurable rowClick="show" bulkActionButtons={false}>
        <TextField source="id" />
        <ReferenceField label="Car" source="carId" reference="car" link="edit" />
        <TextField source="name" />
        <TextField source="phone" />

        <DateField source="pickUpDate" showTime locales="lv-LV" />
        <DateField source="dropOffDate" showTime locales="lv-LV" />
        <DateField source="createdAt" showTime locales="lv-LV" />
      </DatagridConfigurable>
    </List>
  );
};
