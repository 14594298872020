import * as React from 'react';
import { Create, SimpleForm, TextInput, required, TranslatableInputs, NumberInput } from "react-admin";
import { RichTextInput } from 'ra-input-rich-text';
import { locales } from "../enum/locales";

export const OurContactCreateForm = () => (
  <SimpleForm>
    <TranslatableInputs locales={locales} defaultLocale="en" fullWidth={true} >
      <TextInput source="title" validate={[required()]} />
      <RichTextInput source="description" fullWidth />
    </TranslatableInputs>
    <TextInput source="map" multiline={true} fullWidth />
    <NumberInput source="position" validate={[required()]} defaultValue={0} />
  </SimpleForm>
);
export const OurContactCreate = () => (
  <Create>
    <OurContactCreateForm />
  </Create>
);

