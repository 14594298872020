import * as React from 'react';
import { Menu, MenuProps } from 'react-admin';
import QuestionIcon from '@mui/icons-material/QuestionMark';
import CarRentalIcon from '@mui/icons-material/CarRental';
import ImageIcon from '@mui/icons-material/Image';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import SourceIcon from '@mui/icons-material/Source';
import PlaceIcon from '@mui/icons-material/Place';
import ArticleIcon from '@mui/icons-material/Article';

export const AdminMenu = (props: MenuProps) => (
  <Menu {...props}>
    <Menu.Item to="/homeBanners" primaryText="HomeBanners" leftIcon={<ImageIcon />}/>
    <Menu.Item to="/car" primaryText="Car" leftIcon={<CarRentalIcon />}/>
    <Menu.Item to="/carTuning" primaryText="CarTuning" leftIcon={<FormatPaintIcon />}/>
    <Menu.Item to="/faq" primaryText="Faq" leftIcon={<QuestionIcon />}/>
    <Menu.Item to="/content" primaryText="Content" leftIcon={<SourceIcon />}/>
    <Menu.Item to="/ourContact" primaryText="OurContacts" leftIcon={<PlaceIcon />}/>
    <Menu.Item to="/lifestyle" primaryText="Lifestyle" leftIcon={<ArticleIcon />}/>

    <Menu.Item to="/carRent" primaryText="CarRent" leftIcon={<CarRentalIcon />}/>
    <Menu.Item to="/contacts" primaryText="Contacts" leftIcon={<ContactMailIcon />}/>
  </Menu>
);
