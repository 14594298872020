import * as React from 'react';
import {
  Create,
  FormDataConsumer,
  TextInput,
  ImageInput,
  required,
  TranslatableInputs,
  ImageField,
  NumberInput,
  TabbedForm,
  SimpleFormIterator,
  ArrayInput,
} from "react-admin";
import { locales } from "../enum/locales";

export const CarTuningCreateMainForm = () => (
  <>
    <ImageInput source="files" label="Related pictures" multiple={true}>
      <ImageField source="src" title="title" />
    </ImageInput>
    <TranslatableInputs locales={locales} defaultLocale="en" fullWidth={true} >
      <TextInput source="title" validate={[required()]} fullWidth={true} />
      <TextInput source="about" validate={[required()]} multiline={true} fullWidth={true} />
    </TranslatableInputs>
    <ArrayInput source="additional">
      <SimpleFormIterator fullWidth inline>

        <FormDataConsumer>
          {({
              formData, // The whole form data
              scopedFormData, // The data for this item of the ArrayInput
              getSource, // A function to get the valid source inside an ArrayInput
              ...rest
            }) =>

            <TranslatableInputs locales={locales} defaultLocale="en" fullWidth={true} >
              <TextInput source={getSource('title')} fullWidth={true} />
            </TranslatableInputs>

          }
        </FormDataConsumer>
      </SimpleFormIterator>
    </ArrayInput>
    <NumberInput source="position" validate={[required()]} defaultValue={0} />
  </>
);

export const CarTuningCreateForm = () => (
  <TabbedForm>
    <TabbedForm.Tab label='main'>
      <CarTuningCreateMainForm />
    </TabbedForm.Tab>
  </TabbedForm>
);
export const CarTuningCreate = () => (
  <Create>
    <CarTuningCreateForm />
  </Create>
);

